import { Eyebrow, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { useSSOContent } from '@content/client/www/sso/useSSOContent';

const SSOButtons: React.FC<React.PropsWithChildren<{ displayTitle?: boolean }>> = ({
  children,
  displayTitle = true,
}) => {
  const { content: contentSSO } = useSSOContent();
  return (
    <Wrapper data-test-id="ssoSection-btn">
      {displayTitle && (
        <StyledEyebrow data-test-id="ssoSection-title" size="medium">
          {contentSSO?.continuetoCheckoutLegend.value}
        </StyledEyebrow>
      )}
      {children}
    </Wrapper>
  );
};
SSOButtons.displayName = 'SSOButtons';
export default SSOButtons;

const Wrapper = styled.div`
  margin-bottom: ${spacing[16]};

  ${media.desktopLarge`
    margin-bottom: ${spacing[24]};
  `}
`;

const StyledEyebrow = styled(Eyebrow)`
  margin-bottom: ${spacing[16]};

  ${media.desktopLarge`
    margin-bottom: ${spacing[24]};
  `}
`;
