import {
  LegacyEquipmentType,
  hasAccessories as hasAccessoriesFn,
} from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import { EquipmentType } from '@ecomm/graphql/types.generated';

type EquipmentTypeVals = typeof EquipmentType[keyof typeof EquipmentType];

export interface CfuTypesInCart {
  allCfuTypes: EquipmentTypeVals[];
  hasAccessories: boolean;
  hasBike: boolean;
  hasBikePlus: boolean;
  hasTread: boolean;
  hasTreadPlus: boolean;
  hasRow: boolean;
  hasRefurbBike: boolean;
  hasRefurbBikePlus: boolean;
  hasGuide: boolean;
}

const legacyEquipmentTypeToEquipmentType = {
  [LegacyEquipmentType.Bike]: EquipmentType.Bike,
  [LegacyEquipmentType.BikePlus]: EquipmentType.Bikeplus,
  [LegacyEquipmentType.Guide]: EquipmentType.Guide,
  [LegacyEquipmentType.Tread]: EquipmentType.Tread,
  [LegacyEquipmentType.TreadPlus]: EquipmentType.Treadplus,
  [LegacyEquipmentType.Row]: EquipmentType.Row,
  [LegacyEquipmentType.RefurbBike]: EquipmentType.Refurbbike,
  [LegacyEquipmentType.RefurbBikePlus]: EquipmentType.Refurbbikeplus,
};

// Sorting order simply matches current legacy order. Likely revamp as a fast-follow to order based on slowest average shipment time to fastest
// See packages/@ecomm/cart-next/hooks/useGetCfuTypesInCart.ts
export const customSort = (cfuArray: EquipmentTypeVals[]) => {
  const sortOrder = [
    EquipmentType.Treadplus,
    EquipmentType.Tread,
    EquipmentType.Refurbbike,
    EquipmentType.Refurbbikeplus,
    EquipmentType.Bike,
    EquipmentType.Bikeplus,
    EquipmentType.Guide,
    EquipmentType.Row,
  ];

  return cfuArray.sort((a, b) => {
    const indexA = sortOrder.indexOf(a);
    const indexB = sortOrder.indexOf(b);
    return indexA - indexB;
  });
};

export const useGetCfuTypesInCTCart = (): CfuTypesInCart => {
  const { data } = useGetCartItems();
  const items = data?.lineItems ?? [];
  const allLegacyEquipmentTypes = items
    .map(
      item =>
        legacyEquipmentTypeToEquipmentType[
          item?.productVariant?.legacyEquipmentType ?? ''
        ],
    )
    .filter(Boolean);
  const allCfuTypes = customSort(allLegacyEquipmentTypes);
  const hasAccessories = hasAccessoriesFn(items);
  const hasBike = allCfuTypes.includes(EquipmentType.Bike);
  const hasBikePlus = allCfuTypes.includes(EquipmentType.Bikeplus);
  const hasTread = allCfuTypes.includes(EquipmentType.Tread);
  const hasTreadPlus = allCfuTypes.includes(EquipmentType.Treadplus);
  const hasRow = allCfuTypes.includes(EquipmentType.Row);
  const hasRefurbBike = allCfuTypes.includes(EquipmentType.Refurbbike);
  const hasRefurbBikePlus = allCfuTypes.includes(EquipmentType.Refurbbikeplus);
  const hasGuide = allCfuTypes.includes(EquipmentType.Guide);

  return {
    allCfuTypes,
    hasAccessories,
    hasBike,
    hasBikePlus,
    hasTread,
    hasTreadPlus,
    hasRow,
    hasRefurbBike,
    hasRefurbBikePlus,
    hasGuide,
  };
};
