import { Display, Body, spacing } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import Modal from '@ecomm/modal/ModalView';
import type { UserExistsModalProps } from './UserExistsModalTypes';

const UserExistsModal: React.FC<React.PropsWithChildren<UserExistsModalProps>> = ({
  errorDescription,
  heading = '',
  signUpButton,
  handlerOnClose,
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    handlerOnClose && handlerOnClose();
    setOpen(false);
  };

  return (
    <Modal isOpen={open} padded={false} contentLabel={heading} closeHandler={handleClose}>
      <Container>
        {!!heading && (
          <Display
            size={'small'}
            is={'h2'}
            marginBottom={{ mobile: spacing[16], desktop: spacing[24] }}
          >
            {heading}
          </Display>
        )}
        <Body
          size={'small'}
          marginBottom={{ mobile: spacing[48], desktop: spacing[80] }}
          style={{ whiteSpace: 'pre-line' }}
        >
          {errorDescription}
        </Body>
        {signUpButton}
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${spacing[48]} ${spacing[32]};

  ${media.desktop`
    padding: ${spacing[64]} ${spacing[40]};
  `}
`;

export default UserExistsModal;
