import type { Locale } from '@peloton/internationalize';
import type { CommercetoolsPackageQuery } from '@ecomm/shop-configuration';
import { formatProductId } from '@ecomm/shop/graphql/addToCartUtils';
import {
  AccessoryBundleKeys,
  AccessoryKeys,
  CFU_ACCESSORIES,
  CFU_PRODUCT,
  CFU_WARRANTY,
} from '../constants';
import type { ProductCatalogType } from '../models/main';
import type { ProductData } from '../types.generated';
import { calculateBundleDiscount } from '../utils/calculateBundleDiscount';
import { getAttributeValueByName } from '../utils/getAttributeValueByName';
import type { CfuProducts } from '../utils/getCfuProducts';
import { getCfuProducts } from '../utils/getCfuProducts';
import { mapBundleProductsToCfuAccessories } from './mapBundleProductsToCfuAccessories';
import { mapCTBundleToAccessoryBundle } from './mapCTBundleToAccessoryBundle';
import mapRentalEquipmentType from './mapRentalEquipmentType';

export const mapCTCFUtoKronosCFU = (
  ctBundle: ProductCatalogType,
  locale: Locale,
): CommercetoolsPackageQuery => {
  const {
    legacyBundleId,
    name,
    slug,
    description,
    price,
    image,
    availability,
    products,
    discountPrice,
    upsells,
    subscription,
    displayName,
  } = mapCTBundleToAccessoryBundle(ctBundle, locale);

  const { masterData } = ctBundle;
  const bundleBase = masterData.current;
  const { masterVariant } = bundleBase as ProductData;

  const subscriptionAttributes =
    subscription?.masterData.current?.masterVariant.attributesRaw;

  // Temporarily (?) mapping rental equipment types to legacy bike / bike+ equipment types
  const equipmentType = mapRentalEquipmentType(
    getAttributeValueByName<AccessoryBundleKeys>(
      masterVariant.attributesRaw,
      AccessoryBundleKeys.LEGACY_EQUIPMENT_TYPE,
    )?.label ?? '',
  );

  const cfuProducts: CfuProducts = getCfuProducts(products);

  const accessories = mapBundleProductsToCfuAccessories(cfuProducts[CFU_ACCESSORIES]);
  const connectedFitnessUnit = mapBundleProductsToCfuAccessories(
    cfuProducts[CFU_PRODUCT],
  )[0];
  const warranty = mapBundleProductsToCfuAccessories(cfuProducts[CFU_WARRANTY])[0];

  const subscriptionLegacyId =
    subscriptionAttributes &&
    formatProductId(
      getAttributeValueByName<AccessoryKeys>(
        subscriptionAttributes,
        AccessoryKeys.LEGACY_OPTION_ID,
      ),
    );

  const discount = {
    amount: calculateBundleDiscount(price.amount, products),
    currency: price.currency,
  };

  return {
    catalog: {
      packageBySlug: {
        id: formatProductId(legacyBundleId),
        name: displayName || name,
        slug,
        description,
        price,
        discount,
        image,
        availability,
        equipmentType,
        warranty,
        accessories,
        connectedFitnessUnit,
        discountPrice,
        subscriptionId: subscriptionLegacyId,
        upsells,
      },
    },
  };
};
