import { Locale } from '@peloton/internationalize';

import type { AccessoryCommerceTools } from '../../pg-shop-accessories-display/models';
import { AccessoryKeys } from '../constants';
import type { ProductCatalogType } from '../models/main';
import { getProductAvailabilityState } from '../product-states/getAvailabilityState';
import { filterVariantsByAvailability } from '../product-states/getSalesStatus';
import type { ProductData } from '../types.generated';
import { getAttributeValueByName } from '../utils/getAttributeValueByName';
import { sortVariantsByOrder } from '../utils/sortVariantsByOrder';
import { sortVariantsByPrice } from '../utils/sortVariantsByPrice';
import { mapProductAttributesToConfigs } from './mapProductAttributesToConfigs';
import { mapProductAttributesToOptions } from './mapProductAttributesToOptions';

export const mapProductToAccessory = (
  product: ProductCatalogType,
  locale: Locale,
): AccessoryCommerceTools => {
  const { masterData, key, productType } = product;
  const accessoryBase = masterData.current as ProductData;
  const {
    name,
    description,
    masterVariant,
    variants = [],
    categories = [],
  } = accessoryBase;
  const productImages = masterVariant.images || [];
  const { centAmount, currencyCode } = masterVariant.price?.value || {};
  const sku = masterVariant.sku ?? '';

  const accessoryImages = productImages.map(productImage => ({
    src: productImage.url,
    alt: String(productImage.label),
  }));

  const availabilityState = getProductAvailabilityState(accessoryBase, locale);

  const filteredVariants =
    variants.length > 0
      ? filterVariantsByAvailability([masterVariant, ...variants], locale)
      : [masterVariant];

  const configurations = mapProductAttributesToConfigs(
    masterVariant,
    filteredVariants,
    locale,
  );

  const { amount, currency } = configurations[0]?.price || {};

  const accessoryPrice: AccessoryCommerceTools['price'] = {
    amount: amount ?? centAmount,
    currency: currency ?? currencyCode,
  };

  const sortedVariants = sortVariantsByOrder(filteredVariants);
  const attributes = mapProductAttributesToOptions(sortedVariants, locale);
  const legacyProductId: string =
    getAttributeValueByName(
      masterVariant.attributesRaw,
      AccessoryKeys.LEGACY_PRODUCT_ID,
    ) ?? '';

  const sortedByPriceVariants = sortVariantsByPrice(filteredVariants, true);
  const discountPriceBase = sortedByPriceVariants[0]?.price?.discounted?.value;
  const accessoryDiscountedPrice = discountPriceBase && {
    amount: discountPriceBase.centAmount,
    currency: currency ?? currencyCode,
  };

  const displayNameForAllLocales =
    getAttributeValueByName(masterVariant.attributesRaw, AccessoryKeys.DISPLAY_NAME) ??
    {};

  const displayName =
    displayNameForAllLocales[locale] ||
    displayNameForAllLocales[Locale.EnglishUnitedStates];

  const sizeLabelForAllLocales =
    getAttributeValueByName(masterVariant.attributesRaw, AccessoryKeys.SIZE_LABEL) ?? {};

  const sizeLabel =
    sizeLabelForAllLocales[locale] || sizeLabelForAllLocales[Locale.EnglishUnitedStates];

  return {
    id: legacyProductId,
    name: String(name),
    displayName,
    sizeLabel,
    slug: String(key),
    description: (description as unknown) as string,
    images: accessoryImages,
    attributes,
    price: accessoryPrice,
    discountPrice: accessoryDiscountedPrice,
    productType: String(productType?.key),
    availability: availabilityState,
    variants: configurations,
    categories: categories.map(c => c.id),
    sku,
  };
};
