import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import type { Locale } from '@peloton/internationalize';
import type { AvailabilityState } from '@ecomm/product-states/models/availability';
import { AVAILABLE, UNAVAILABLE } from '@ecomm/product-states/models/availability';
import { ProductStates } from '@ecomm/product-states/models/productState';
import { AccessoryBundleKeys } from '../constants';
import type { ProductCatalogType } from '../models/main';
import type { ProductData } from '../types.generated';
import { getAttributeRefResourceSetByName } from '../utils/getAttributeRefResourceSetByName';
import { getChannelAvailabilityStatus } from './getChannelAvailabilityStatus';
import { getSalesStatus } from './getSalesStatus';

export const getProductAvailabilityState = (
  productData: ProductData,
  locale: Locale,
): AvailabilityState => {
  const { variants = [], masterVariant } = productData;
  const channelAvailabilityStatus = getChannelAvailabilityStatus(masterVariant);

  if (channelAvailabilityStatus === ProductStates.Unavailable) {
    return {
      state: ProductStates.Unavailable,
      code: UNAVAILABLE, // No need to calculate sales status
    };
  }

  // Product sales status is equal to max variant status code
  const salesStatus =
    variants.length > 0
      ? maxBy(
          [masterVariant, ...variants].map(variant => getSalesStatus(variant, locale)),
          'code',
        )!
      : getSalesStatus(masterVariant, locale);

  const salesStatusAvailability = {
    state: salesStatus.state,
    code: salesStatus.code,
  };

  const inventoryAvailability = { code: AVAILABLE, state: ProductStates.Available };

  // Return AvailabilityState with smallest code property: "Unavailable" should beat "sold out"
  return minBy([salesStatusAvailability, inventoryAvailability], 'code')!;
};

export const getBundleAvailabilityState = (
  productData: ProductData,
  locale: Locale,
): AvailabilityState => {
  const bundleAvailabilityState = getProductAvailabilityState(productData, locale);
  const bundleProducts = getAttributeRefResourceSetByName<AccessoryBundleKeys>(
    productData.masterVariant.attributesRaw,
    AccessoryBundleKeys.PRODUCT_REFERENCES,
  ) as ProductCatalogType[];
  const productAvailabilityStates = bundleProducts.map(product =>
    getProductAvailabilityState(product.masterData.current!, locale),
  );

  // Bundle sales status is equal to min product status code or bundle status code itself
  const salesStatus = minBy(
    [bundleAvailabilityState, ...productAvailabilityStates],
    'code',
  )!;

  return { state: salesStatus.state, code: salesStatus.code };
};

export const getCurrentAvailabilityState = (
  productData: ProductData,
  locale: Locale,
): AvailabilityState => {
  const { masterVariant, variants = [] } = productData;
  const channelAvailabilityStatus = getChannelAvailabilityStatus(masterVariant);
  const salesStatusCode = variants.length
    ? { code: AVAILABLE, state: ProductStates.Available }
    : getSalesStatus(masterVariant, locale);

  return {
    state:
      channelAvailabilityStatus === ProductStates.Unavailable
        ? channelAvailabilityStatus
        : salesStatusCode.state,
    code: salesStatusCode.code,
  };
};
