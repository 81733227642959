import { grey, Label } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';

type Props = { orText: string };

const OrText: React.FC<React.PropsWithChildren<Props>> = ({ orText }) => {
  return (
    <div style={{ width: '100%' }}>
      <OrComponent size="small" weight="medium">
        {orText}
      </OrComponent>
    </div>
  );
};

export default OrText;

const OrComponent = styled(Label)`
  flex: 1;
  width: 100%;
  color: ${grey[70]};
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  &:before,
  &:after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid ${grey[70]};
    margin: auto;
  }
  &:before {
    margin-right: 8px;
  }
  &:after {
    margin-left: 8px;
  }
`;
