import {
  grey,
  Input,
  spacing,
  black,
  red,
  white,
  Button,
  Grid,
  Icon,
  Flex,
} from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import styled from 'styled-components';
import useCartContent from '@content/client/www/cart/useCart';
import useCheckoutContent from '@content/client/www/checkout/useCheckout';
import usePromoCodeAnalytics from '@ecomm/cart-next/analytics/usePromoCodeAnalytics';
import { useSetIsCartLoading } from '@ecomm/cart-next/context/CartContext';
import { callUpdateCartMutation } from '@ecomm/checkout-commercetools/helpers/cartHelper';
import { getCountryCart } from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import { DiscountType, ShopCartUpdateActionType } from '@ecomm/graphql/types.generated';
import { useCreateShopCartJupiterMutation } from '@ecomm/shop-cart/graphql/mutations/CreateCart.generated';
import { useUpdateShopCartJupiterMutation } from '@ecomm/shop-cart/graphql/mutations/UpdateCart.generated';
import { b4, b6, reg } from '@ecomm/typography';

const AddPromoCode: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { content: cartContent } = useCartContent();
  const { content: checkoutContent } = useCheckoutContent();
  const [isCouponExists, setIsCouponExists] = useState<boolean>(true);
  const [discountCoupon, setDiscountCoupon] = useState<string>('');
  const { data: shopCart } = useGetCartItems();
  const [updateCartMutation] = useUpdateShopCartJupiterMutation();
  const [createCartMutation] = useCreateShopCartJupiterMutation();
  const [isOpen, setIsOpen] = useState(false);
  const setIsCartLoading = useSetIsCartLoading();
  const { trackAppliedPromoCode } = usePromoCodeAnalytics();

  const handleInvalidCoupon = () => {
    setIsCouponExists(false);
    setIsOpen(true);
  };

  const applyDiscountCouponFn = async () => {
    if (!Boolean(discountCoupon)) {
      handleInvalidCoupon();
      return;
    }

    setIsCartLoading(true);
    setIsCouponExists(true);

    const country = getCountryCart();
    const actions = [
      {
        addDiscount: {
          code: discountCoupon,
          type: DiscountType.UserAppliedDiscount,
        },
        actionType: ShopCartUpdateActionType.AddDiscount,
      },
    ];

    try {
      await callUpdateCartMutation(
        actions,
        country,
        shopCart,
        updateCartMutation,
        createCartMutation,
      );

      trackAppliedPromoCode(shopCart, discountCoupon);

      setIsOpen(false);
      setIsCartLoading(false);
    } catch (e) {
      setIsCartLoading(false);
      handleInvalidCoupon();
    }
  };

  const handlePromoCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setDiscountCoupon(input);
  };

  return !isOpen ? (
    <StyledAddCoupon
      data-test-id="revealCouponCodeInput"
      onClick={() => {
        setIsOpen(true);
      }}
    >
      {cartContent?.enterPromoCode?.value}
    </StyledAddCoupon>
  ) : (
    <>
      <StyledGrid gap={spacing[4]}>
        <Input
          data-test-id="promo-code-input"
          aria-label={cartContent?.enterPromoCode?.value}
          label={cartContent?.enterPromoCode?.value}
          name="cart-promo-code"
          onChange={handlePromoCodeChange}
        />
        <Button
          size="large"
          color={'dark'}
          variant="solid"
          data-test-id="applyCouponCode"
          width="adjustable"
          onClick={applyDiscountCouponFn}
        >
          {cartContent?.apply?.value}
        </Button>
      </StyledGrid>
      {!isCouponExists && (
        <ErrorContainer data-test-id="promo-code-error-msg">
          <StyledIcon
            name="exclamation"
            height={15}
            primaryColor={white}
            secondaryColor={red[50]}
            data-test-id="apply-coupon-error"
          />
          {checkoutContent?.invalidPromoCode?.value}
        </ErrorContainer>
      )}
    </>
  );
};

const StyledAddCoupon = styled.button`
  ${b4}
  ${reg}
  color: ${black};
  text-decoration: underline;
  &:hover {
    color: ${grey[70]};
  }
`;

const StyledGrid = styled(Grid)`
  grid-template-columns: auto 78px;
  padding: ${spacing[8]} 0 0;
`;

const ErrorContainer = styled(Flex)`
  ${b6}
  ${reg}
  background: ${red[50]};
  color: ${white};
  display: inline-flex;
  padding: ${spacing[4]} ${spacing[8]};
  margin-top: ${spacing[8]};
  align-items: center;
  border-radius: ${spacing[2]};
`;

const StyledIcon = styled(Icon)`
  margin-right: ${spacing[8]};
`;

export default AddPromoCode;
