import { useLocale } from '@peloton/internationalize';
import { useGetCommercetoolsCFU } from '@ecomm/commercetools/hooks/useGetCommercetoolsCFU';
import { localizedBasicPackagesMap } from '@ecomm/shop/localizedBasicPackagesMap';
import type { BundleType } from '@ecomm/shop/models';

const useCtBundleProducts = (bundleType: BundleType) => {
  const locale = useLocale();
  const basicsPackages = localizedBasicPackagesMap[locale]?.[bundleType];

  return useGetCommercetoolsCFU(basicsPackages);
};

export default useCtBundleProducts;
