import { Container, grey, Support } from '@pelotoncycle/design-system';
import React from 'react';
import { getCartTotal } from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import { Partners } from '@ecomm/financing/models/Partners';
import { financingBundleTypeFromCart } from '@ecomm/financing/models/term';
import TruemedCopy from '@ecomm/financing/truemed/TruemedCopy';
import { useGetCfuTypesInCTCart } from '@ecomm/pg-checkout-commercetools/hooks/useGetCfuTypesInCTCart';
import type { BundleType } from '@ecomm/shop/models';

type Props = { className?: string; alternativePartner: Partners | null | undefined };

const getAlternativePartner = (
  alternativePartner: Partners | null | undefined,
  bundleType: BundleType,
  price: number,
) => {
  switch (alternativePartner) {
    case Partners.Truemed:
      return <TruemedCopy bundleType={bundleType} price={price} />;
    default:
      return null;
  }
};

const AlternativePartnerContainer: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  alternativePartner,
}) => {
  const { data: shopCart } = useGetCartItems();
  const {
    hasBike: hasBikeInCart,
    hasBikePlus: hasBikePlusInCart,
    hasTread: hasTreadInCart,
    hasTreadPlus: hasTreadPlusInCart,
    hasRow: hasRowInCart,
    hasGuide: hasGuideInCart,
  } = useGetCfuTypesInCTCart();
  const financingBundleType = financingBundleTypeFromCart({
    hasBikeInCart,
    hasBikePlusInCart,
    hasTreadInCart,
    hasTreadPlusInCart,
    hasGuideInCart,
    hasRowInCart,
  });
  const cartTotal = getCartTotal(shopCart);

  return (
    <Container className={className}>
      <Support size="large" textColor={grey[70]}>
        {getAlternativePartner(alternativePartner, financingBundleType, cartTotal)}
      </Support>
    </Container>
  );
};

export default AlternativePartnerContainer;
