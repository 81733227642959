import { Locale, useLocale } from '@peloton/internationalize';
import { useHasVariation } from '@peloton/split-testing/hooks';

const TWO_MONTHS_AAM = 'Tread Delivery All Access Promo';
const VARIATION_1 = 'Variation #1';

export const useTreadDeliveryAAMPromo = (
  cartHasTread: boolean,
  cartHasTreadPlus: boolean,
) => {
  const experimentRunning = Boolean(useHasVariation(TWO_MONTHS_AAM, VARIATION_1));
  const locale = useLocale();

  switch (locale) {
    case Locale.EnglishUnitedStates:
      return experimentRunning && cartHasTreadPlus;
    case Locale.EnglishUnitedKingdom:
      return experimentRunning && cartHasTread;
    default:
      return experimentRunning;
  }
};
