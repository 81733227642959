import React from 'react';
import type { LogoProps } from '@peloton/logos';
import { oneLine } from '@peloton/text/index';

type IconSizes = 'small' | 'medium' | 'large';

enum IconSizesValues {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

type ZipLogoAdditionalProps = {
  ariaID?: string;
  size?: IconSizes;
  primaryColor?: string;
  secondaryColor?: string;
};

export type ZipLogoProps = LogoProps & ZipLogoAdditionalProps;

const DEFAULT_PRIMARY_COLOR = '#000000';
const DEFAULT_SECONDARY_COLOR = '#AA8FFF';

const largeIconSize = { width: '66px', height: '25px' };
const mediumIconSize = { width: '51px', height: '20px' };
const smallIconSize = { width: '33px', height: '12px' };

const iconSizeMap = {
  [IconSizesValues.Small]: smallIconSize,
  [IconSizesValues.Medium]: mediumIconSize,
  [IconSizesValues.Large]: largeIconSize,
};

const getIconSize = (size: IconSizes) => {
  return iconSizeMap?.[size] || smallIconSize;
};

const ZipLogo: React.FC<React.PropsWithChildren<ZipLogoProps>> = ({
  ariaID,
  primaryColor = DEFAULT_PRIMARY_COLOR,
  secondaryColor = DEFAULT_SECONDARY_COLOR,
  size = 'small',
  ...props
}) => {
  const labelID = `zip-icon-title${ariaID ? `-${ariaID}` : ''}`;
  const { width, height } = getIconSize(size);

  return (
    <svg viewBox="0 0 33 12" fill="none" height={height} width={width} {...props}>
      <title id={labelID}>Zip</title>
      <mask
        id={`mask0_234_6088${ariaID ? `-${ariaID}` : ''}`}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <path d="M33 0H0V12H33V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_234_6088)">
        <path
          d={oneLine(`M0.852393 9.31742L1.13172 11.363H10.9112L10.5912 9.02036H6.03134L5.9915 8.73007L10.1911 
          6.09228L9.91052 4.04297H0.131836L0.45185 6.3852H5.01929L5.05913 6.67812L0.852393 9.31742Z`)}
          fill={primaryColor}
        />
        <path
          d="M11.0466 4.04297L12.0469 11.363H21.8336L20.8333 4.04297H11.0466Z"
          fill={secondaryColor}
        />
        <path
          d={oneLine(`M32.0964 6.67816C31.8707 5.03298 30.433 4.03584 28.4785 4.04301H21.9688L22.9686
            11.3634H25.8974L25.6969 9.89916H28.796C31.2349 9.89878 32.351 8.53295 32.0964 6.67816ZM28.4793
            7.84683L25.4176 7.84984L25.1777 6.09307L28.2566 6.09533C28.9805 6.10325 29.3509 6.46968 29.4109
            6.97108C29.4478 7.29378 29.2834 7.84645 28.4793 7.84645V7.84683Z`)}
          fill={primaryColor}
        />
        <path
          d={oneLine(`M13.9706 3.07021C14.4857 2.5732 14.3959 1.74552 13.7701 1.22154C13.1443 0.697552 
            12.2194 0.675681 11.7044 1.17269C11.1893 1.6697 11.2791 2.49737 11.9049 3.02136C12.5307
            3.54535 13.4556 3.56722 13.9706 3.07021Z`)}
          fill={primaryColor}
        />
      </g>
    </svg>
  );
};

const ZipLogoLarge = () => {
  return <ZipLogo size="medium" />;
};

export { ZipLogo, ZipLogoLarge };
