import { Icon, spacing, white } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Modal from '@ecomm/modal/ModalView';

type TruemedModalType = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
};

const CONTENT_CONTAINER_ID = 'modal-content-container';
const HEADER_HEIGHT = 56;

const TruemedModal: React.FC<React.PropsWithChildren<TruemedModalType>> = ({
  isOpen,
  setIsOpen,
}) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const handleClose = () => {
    setIsOpen(false);
    setIsLoading(true);
  };

  const handleAfterOpen = () => {
    const contentContainer = document.getElementById(CONTENT_CONTAINER_ID);
    const width = Number(contentContainer?.offsetWidth);
    const height =
      window.innerHeight -
      Number(contentContainer?.getBoundingClientRect().y) -
      100 -
      HEADER_HEIGHT;
    setSize({
      width,
      height,
    });
  };
  return (
    <>
      <ContentModalStyle />
      <Modal
        isOpen={isOpen}
        closeHandler={handleClose}
        padded={false}
        contentLabel=""
        openHandler={handleAfterOpen}
        id={CONTENT_CONTAINER_ID}
      >
        <StyledHeader>
          <Icon name="trueMed" height={24} />
        </StyledHeader>
        {isLoading ? (
          <StyledIconWrapper style={{ width: size.width, height: size.height }}>
            <Icon name="spinner" height={48} />
          </StyledIconWrapper>
        ) : null}
        <StyledIframe
          title="Truemed widget"
          src="https://app.truemed.com/peloton-widget"
          width={size.width}
          height={size.height}
          onLoad={() => setIsLoading(false)}
        />
      </Modal>
    </>
  );
};

export default TruemedModal;

const ContentModalStyle = createGlobalStyle`
#${CONTENT_CONTAINER_ID} {
  background-color: transparent;
}`;

const StyledIframe = styled.iframe`
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const StyledHeader = styled.div`
  padding: ${spacing[16]} ${spacing[24]};
  background-color: ${white};
  text-align: left;

  svg {
    display: block;
  }
`;

const StyledIconWrapper = styled.div`
  position: absolute;
  background-color: ${white};
  display: flex;
  justify-content: center;
  align-items: center;
`;
