import { Eyebrow, Button, grey, white } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { AppleSocialIcon, GoogleSocialIcon } from '@ecomm/icons';
import type { SocialSignUpButtonProps } from './SocialSignUpButtonTypes';

const SocialSignUpButton: React.FC<React.PropsWithChildren<SocialSignUpButtonProps>> = ({
  type,
  buttonClickHandler,
  buttonText,
  dataTestId,
  mobileAlignment,
}) => {
  const [appleIconColor, setAppleIconColor] = useState<string>(grey[90]);

  const SocialButton =
    mobileAlignment == 'vertical' ? SocialButtonVertical : SocialButtonHorizontal;

  return (
    <SocialButton
      onClick={buttonClickHandler}
      variant="outline"
      color="dark"
      onMouseOver={() => setAppleIconColor(white)}
      onMouseLeave={() => setAppleIconColor(grey[90])}
      style={{
        padding: 0,
        minWidth: mobileAlignment == 'vertical' ? '200px' : undefined,
        width: mobileAlignment == 'vertical' ? '100%' : '50%',
      }}
      data-test-id={dataTestId}
    >
      {type === 'apple' && (
        <AppleSocialIcon overflow="unset" height={20} fill={appleIconColor} />
      )}
      {type === 'google' && <GoogleSocialIcon overflow="unset" height={20} />}
      <Eyebrow size={'small'} is={'span'} style={{ position: 'unset' }}>
        {buttonText}
      </Eyebrow>
    </SocialButton>
  );
};

const SocialButtonHorizontal = styled(Button)<{
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
  width: 50%;
  flex: 1;
`;

const SocialButtonVertical = styled(Button)<{
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
}>`
  display: flex;
  gap: 11px;
  ${media.desktop`
    align-items: center;
    justify-content: center;
    width: 50%;
    flex: 1;
  `};
`;

export default SocialSignUpButton;
