import React from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { getCountryCart } from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import type { MutationResult } from '@ecomm/graphql/hooks';
import type { CtCartFragment } from '@ecomm/shop-cart/graphql/fragments.generated';
import type { UpdateShopCartJupiterMutation } from '@ecomm/shop-cart/graphql/mutations/UpdateCart.generated';
import { useUpdateShopCartJupiterMutation } from '@ecomm/shop-cart/graphql/mutations/UpdateCart.generated';
import type {
  CountryCode,
  Maybe,
  ShopCartUpdateAction,
  UpdateShopCartInput,
} from '@ecomm/shop-cart/graphql/types.generated';
import { debounce } from '../../../utils/debounce';
import {
  getEmailUpdateIfNeeded,
  getPrivacyUpdateIfNeeded,
  getShippingAddressUpdateIfNeeded,
  getBillingAddressUpdateIfNeeded,
} from './updateActionStateGenerators';

type UpdateCartParams = {
  variables?: { input?: Maybe<UpdateShopCartInput> };
};

type UpdateCartMutation = (
  params: UpdateCartParams,
) => Promise<MutationResult<UpdateShopCartJupiterMutation>>;

export const useUpdateCheckoutForm = () => {
  const { data: shopCart } = useGetCartItems();
  const [updateCart, loading] = useUpdateShopCartJupiterMutation();
  const country = getCountryCart();
  const form = useFormContext();
  const updateInProgress: React.MutableRefObject<boolean> = React.useRef<boolean>(false);

  const updateCartState = React.useCallback(
    async (actions: ShopCartUpdateAction[]) =>
      // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
      // @ts-ignore
      updateCartStateCallback(updateCart, actions, country, updateInProgress),
    [country, updateCart],
  );

  const commitFormState = React.useCallback(
    debounce(
      async () =>
        commitFormStateCallback(updateCartState, shopCart, form, updateInProgress),
      500,
    ),
    [form, shopCart, updateCartState],
  );

  const asyncCommitFormState = React.useCallback(
    async () =>
      await commitFormStateCallback(updateCartState, shopCart, form, updateInProgress),
    [form, shopCart, updateCartState],
  );

  return {
    ...form,
    commitFormState,
    asyncCommitFormState,
    loading: Boolean(loading),
  };
};

export const commitFormStateCallback = async (
  updateCartState: (actions: ShopCartUpdateAction[]) => Promise<any>,
  shopCart: CtCartFragment | null | undefined,
  form: UseFormReturn,
  updateInProgress: React.MutableRefObject<boolean>,
) => {
  if (!updateInProgress.current && shopCart) {
    const actions = [
      ...getEmailUpdateIfNeeded(shopCart, form),
      ...getPrivacyUpdateIfNeeded(shopCart, form),
      ...getShippingAddressUpdateIfNeeded(shopCart, form),
      ...getBillingAddressUpdateIfNeeded(shopCart, form),
    ];
    try {
      await updateCartState(actions);
    } catch (error) {
      console.error('Error updating cart:', error);
    }
  }
};

export const updateCartStateCallback = async (
  updateCart: UpdateCartMutation,
  actions: ShopCartUpdateAction[],
  country: CountryCode,
  updateInProgress: React.MutableRefObject<boolean>,
) => {
  if (!updateInProgress.current) {
    if (!actions.length) {
      return;
    }
    updateInProgress.current = true;

    try {
      await updateCart({
        variables: { input: { actions, country } },
      });
    } finally {
      updateInProgress.current = false;
    }
  }
};
