import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { Address as APIAddress } from '@ecomm/models';

export enum CreditCardField {
  CardholderName = 'Cardholder Name',
  CardNumber = 'Card Number',
  CardCVC = 'Card CVC',
  CardExpiry = 'Card Expiry',
}

export enum CheckoutProgress {
  CheckoutBegun = 'Checkout Begun',
  CheckoutInReview = 'Checkout In Review',
}

export enum CartStatus {
  Loading,
  Ready,
}

export enum SelectedPayment {
  Affirm = 'Affirm Financing',
  ApplePay = 'ApplePay',
  Citizens = 'Citizens',
  CreditCard = 'Credit Card',
  Default = 'Default',
  GooglePay = 'GooglePay',
  GiftCard = 'Gift Card',
  Klarna = 'Klarna',
  Zip = 'Zip',
  Truemed = 'Truemed',
}

interface CheckoutState {
  checkoutProgress?: CheckoutProgress;
  creditCardErrors: Record<CreditCardField, boolean>;
  resetCreditCardErrors: () => void;
  setCreditCardError: (fieldName: CreditCardField, hasError: boolean) => void;
  setCheckoutProgress: (checkoutProgress: CheckoutProgress) => void;
  shouldDisplayAllAccessMembership: boolean;
  setShouldDisplayAllAccessMembership: (value: boolean) => void;
  cartStatus: CartStatus;
  setCartStatus: (cartStatus: CartStatus) => void;
  isPaymentError: boolean;
  setIsPaymentError: (value: boolean) => void;
  paymentErrorType?: string;
  setPaymentErrorType: (type: string | undefined) => void;
  selectedPaymentType: SelectedPayment;
  setSelectedPaymentType: (type: string) => void;
  suggestedAddress: APIAddress | null;
  setSuggestedAddress: (address: APIAddress | null) => void;
  isValidationTriggered: boolean;
  setIsValidationTriggered: (isValidationTriggered: boolean) => void;
  errorMessage: string;
  setErrorMessage: (errorMessage: string) => void;
  isGiftingChecked: boolean;
  setIsGiftingChecked: (isGiftingChecked: boolean) => void;
}

interface ReferralCodeValidState {
  isValidReferralCode: boolean;
  setIsValidReferralCode: (error: boolean) => void;
}

const initCreditCardErrors = {
  [CreditCardField.CardholderName]: true,
  [CreditCardField.CardNumber]: true,
  [CreditCardField.CardExpiry]: true,
  [CreditCardField.CardCVC]: true,
};

export const useCheckoutStore = create<CheckoutState>()(set => ({
  checkoutProgress: undefined,
  shouldDisplayAllAccessMembership: true,
  suggestedAddress: null,
  isPaymentError: false,
  creditCardErrors: initCreditCardErrors,
  setCreditCardError: (fieldName, hasError) =>
    set(state => ({
      creditCardErrors: { ...state.creditCardErrors, ...{ [fieldName]: hasError } },
    })),
  resetCreditCardErrors: () =>
    set({
      creditCardErrors: initCreditCardErrors,
    }),
  setCheckoutProgress: (checkoutProgress: CheckoutProgress) => set({ checkoutProgress }),
  setShouldDisplayAllAccessMembership: (value: boolean) =>
    set({ shouldDisplayAllAccessMembership: value }),
  cartStatus: CartStatus.Ready,
  setCartStatus: (cartStatus: CartStatus) => set({ cartStatus }),
  setIsPaymentError: (value: boolean) => set({ isPaymentError: value }),
  selectedPaymentType: SelectedPayment.Default,
  setSelectedPaymentType: (selectedPaymentType: SelectedPayment) =>
    set({ selectedPaymentType }),
  paymentErrorType: undefined,
  setPaymentErrorType: (paymentErrorType: string | undefined) =>
    set({ paymentErrorType }),
  setSuggestedAddress: (suggestedAddress: APIAddress | null) => set({ suggestedAddress }),
  isValidationTriggered: false,
  setIsValidationTriggered: (isValidationTriggered: boolean) =>
    set({ isValidationTriggered }),
  errorMessage: '',
  setErrorMessage: (errorMessage: string) => set({ errorMessage }),
  isGiftingChecked: false,
  setIsGiftingChecked: (isGiftingChecked: boolean) => set({ isGiftingChecked }),
}));

export const useIsReferralCodeValidStore = create(
  persist<ReferralCodeValidState>(
    (set, get) => ({
      isValidReferralCode: true,
      setIsValidReferralCode: (isValid: boolean) => set({ isValidReferralCode: isValid }),
    }),
    {
      name: 'valid-referral-code', // name of the item in the storage (must be unique)
    },
  ),
);

export const useSetCreditCardError = () =>
  useCheckoutStore(state => state.setCreditCardError);

export const useHasCreditCardErrors = () => {
  const errors = useCheckoutStore(state => state.creditCardErrors);
  return Object.values(errors).some(Boolean);
};

export const useResetCreditCardErrors = () =>
  useCheckoutStore(state => state.resetCreditCardErrors);

export const useSetIsPaymentError = () =>
  useCheckoutStore(state => state.setIsPaymentError);

export const useIsPaymentError = () => useCheckoutStore(state => state.isPaymentError);

export const useCheckoutProgress = () =>
  useCheckoutStore(state => state.checkoutProgress);

export const useSetCheckoutProgress = () =>
  useCheckoutStore(state => state.setCheckoutProgress);

export const useShouldDisplayAllAccessMembership = () =>
  useCheckoutStore(state => state.shouldDisplayAllAccessMembership);

export const useSetShouldDisplayAllAccessMembership = () =>
  useCheckoutStore(state => state.setShouldDisplayAllAccessMembership);

export const useIsCartStatusLoading = () =>
  useCheckoutStore(state => state.cartStatus === CartStatus.Loading);

const useSetCartStatus = () => useCheckoutStore(state => state.setCartStatus);

export const useSetCartStatusLoading = () => {
  const setCartStatus = useSetCartStatus();
  return () => setCartStatus(CartStatus.Loading);
};

export const useSetCartStatusReady = () => {
  const setCartStatus = useSetCartStatus();
  return () => setCartStatus(CartStatus.Ready);
};

export const useIsValidReferralCode = () =>
  useIsReferralCodeValidStore(state => state.isValidReferralCode);

export const useSetIsValidReferralCode = () =>
  useIsReferralCodeValidStore(state => state.setIsValidReferralCode);

export const useSelectedPaymentType = () =>
  useCheckoutStore(state => state.selectedPaymentType);

export const useSetSelectedPaymentType = () =>
  useCheckoutStore(state => state.setSelectedPaymentType);

export const usePaymentErrorType = () =>
  useCheckoutStore(state => state.paymentErrorType);

export const useSetPaymentErrorType = () =>
  useCheckoutStore(state => state.setPaymentErrorType);

export const useSuggestedAddress = () => {
  const { suggestedAddress, setSuggestedAddress } = useCheckoutStore(state => ({
    suggestedAddress: state.suggestedAddress,
    setSuggestedAddress: state.setSuggestedAddress,
  }));

  return { suggestedAddress, setSuggestedAddress };
};

export const useIsValidationTriggered = () =>
  useCheckoutStore(state => state.isValidationTriggered);

export const useSetIsValidationTriggered = () =>
  useCheckoutStore(state => state.setIsValidationTriggered);

export const useErrorMessage = () => useCheckoutStore(state => state.errorMessage);

export const useSetErrorMessage = () => useCheckoutStore(state => state.setErrorMessage);

export const useIsGiftingChecked = () =>
  useCheckoutStore(state => state.isGiftingChecked);

export const useSetIsGiftingChecked = () =>
  useCheckoutStore(state => state.setIsGiftingChecked);
