import { create } from 'zustand';

type triggerVerificationState = {
  triggerVerification: boolean;
  setTriggerVerification: (value: boolean) => void;
};

const useTriggerVerificationState = create<triggerVerificationState>(set => ({
  triggerVerification: false,
  setTriggerVerification: (value: boolean) => set({ triggerVerification: value }),
}));

export const useVerficationTriggered = () =>
  useTriggerVerificationState(state => state.triggerVerification);
export const useSetTriggerVerification = () =>
  useTriggerVerificationState(state => state.setTriggerVerification);
