import { track } from '@peloton/analytics/analytics';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import type { BundleType } from '@ecomm/shop/models';
import type { PageType } from '../affirm/AffirmCopy';
import { Partners } from '../models/Partners';

export const toAnalyticUnitName = (name: PageType) =>
  name === 'product'
    ? 'package card'
    : name === 'configure'
    ? 'package modal'
    : name === 'payment'
    ? 'cart'
    : name;

export const trackClick = (
  billingPartner: Partners,
  bundleType: BundleType,
  pageType: PageType,
  price: number,
  customWidget?: boolean,
) => (event: React.MouseEvent<Element, MouseEvent>) => {
  if (event.target instanceof Element) {
    const className = event.target.className;
    if (
      !customWidget &&
      billingPartner === Partners.Affirm &&
      className !== 'affirm-modal-trigger'
    ) {
      return;
    }
  } else {
    return;
  }

  track({
    event: 'Clicked Prequalify CTA',
    properties: {
      pageName: getCurrentRelativePathname(),
      price,
      productName: bundleType,
      unitName: toAnalyticUnitName(pageType),
      billingPartner,
    },
  });
};
