import { Currency, toCurrency } from '@peloton/internationalize';
import { ShopPaymentType } from '@ecomm/graphql/types.generated';

export const MIN_AFFIRM = 10000;
export const MIN_CITIZENS = 29500;
export const MIN_ZIP = 30000;

const toRemainingTotalAmountEligibleForFinancing = (
  amount: number,
  partner: ShopPaymentType,
) => {
  const currency = toCurrency();

  switch (partner) {
    case ShopPaymentType.AffirmFinancing:
      return (
        (currency === Currency.UnitedStatesDollars ||
          currency === Currency.CanadianDollar) &&
        amount >= MIN_AFFIRM
      );
    case ShopPaymentType.CitizensFinancing:
      return currency === Currency.UnitedStatesDollars && amount >= MIN_CITIZENS;
    case ShopPaymentType.KlarnaFinancing:
    case ShopPaymentType.TruemedHsaFsa:
      // TODO: PCAM-8053 Update logic here with Klarna min
      return true;
    case ShopPaymentType.ZipFinancing:
      return currency === Currency.AustralianDollars && amount >= MIN_ZIP;
    default:
      return false;
  }
};

export default toRemainingTotalAmountEligibleForFinancing;
