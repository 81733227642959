import React, { useState, useCallback, useEffect } from 'react';
import { useTracking } from 'react-tracking';
import { RETURN_TO_STORAGE_KEY } from '@peloton/auth/constants';
import { useOauth } from '@peloton/auth/OauthProvider';
import type { ApiMeUser } from '@peloton/auth/types';
import { usePathname, useQueryParams } from '@peloton/hooks';
import useLocalStorage from '@peloton/hooks/shared/useLocalStorage';
import { addCurrentLocaleToUrl } from '@peloton/internationalize/addCurrentLocaleToUrl';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import { useSSOContent } from '@content/client/www/sso/useSSOContent';
import SocialSignUpButtons from '../components/SocialSignUpButtons/SocialSignUpButtons';
import SSOButtons from '../components/SSOButtons';
import {
  AnalyticUnitName,
  Connection,
  ParentTypeAnalytic,
  SocialButtonTypes,
  APP_MEMBERSHIP_CHECKOUT_ROUTE,
  CALLBACK,
  NEXT_CALLBACK,
} from '../constants';
import { getRedirectPath, queryObjToString, toAppState } from '../helpers';
import useSocialLinkingErrorModal from './useSocialLinkingErrorModal';

export type EventProps = {
  parentType: string;
  linkTo: string;
  unitName: string;
  linkName: string;
  parent?: string;
  clickedFrom?: string | null;
  page?: string;
  isLoggedIn?: string;
  hasDeviceSubscription?: string;
  hasDigitalSubscription?: string;
};

export type TrackData = {
  event: string;
  properties?: EventProps;
  instanceProperties?: EventProps;
};

export type Props = {
  promoSlug?: string;
  slug?: string;
  isNextAuthLoginActive?: boolean;
  isSignUpUpdatesActive?: boolean;
  showLoginSupport?: boolean;
  displayTitle?: boolean;
  showOrEmailOption?: boolean;
  mobileAlignment?: 'vertical' | 'horizontal';
  user?: ApiMeUser | {};
  track?: (data: TrackData) => void;
};

export type SSOResult = {
  Buttons: JSX.Element;
  isUserCreationFormShown: boolean;
  isErrorDialogShown: boolean;
  ErrorDialog: JSX.Element;
  ssoButtonHandler: (
    type: SocialButtonTypes.APPLE | SocialButtonTypes.GOOGLE,
  ) => () => void;
  setUserCreationFormVisible: (state: boolean) => void;
};

const trackAnalytics = (
  trackEvent: (data: TrackData) => void,
  props: EventProps,
  isAccount: boolean,
) => {
  const properties = isAccount ? 'instanceProperties' : 'properties';
  return trackEvent({
    event: 'Clicked Link',
    [properties]: {
      parent: 'SSO Buttons',
      clickedFrom: getCurrentRelativePathname(),
      ...props,
    },
  });
};

export const useSSOButtons = (app: string, props?: Props): SSOResult => {
  const [isUserCreationFormShown, setIsUserCreationFormShown] = useState<boolean>(false);
  const [, setReturnTo] = useLocalStorage(RETURN_TO_STORAGE_KEY, '');
  const { isErrorDialogShown, ErrorDialog } = useSocialLinkingErrorModal(app);
  const { loginWithRedirect } = useOauth();
  const pathname = usePathname();
  const query = useQueryParams();
  const { trackEvent } = useTracking();
  const { content: contentSSO } = useSSOContent();
  //as account app has own wrapper for trackEvent we should pass it as prop.track
  const track = props?.track ?? trackEvent;
  const isAccount = app === 'account';

  useEffect(() => {
    if (props?.isSignUpUpdatesActive) {
      setIsUserCreationFormShown(true);
    }
  }, [props?.isSignUpUpdatesActive]);

  const ssoButtonHandler = useCallback(
    (type: SocialButtonTypes.APPLE | SocialButtonTypes.GOOGLE) => () => {
      const queryStr = queryObjToString(query);
      const { slug, promoSlug } = props ?? {};
      const redirectUrl = getRedirectPath({
        pathname,
        queryStr,
        slug,
        promoSlug,
      });
      setReturnTo(redirectUrl);

      const typeButtonCopy = `${type}ButtonText`;
      const isNextApp = app === 'next-www';

      trackAnalytics(
        track,
        {
          linkName: contentSSO?.[typeButtonCopy].value,
          unitName: AnalyticUnitName[app][type],
          linkTo: '',
          parentType: ParentTypeAnalytic[app],
          page: isNextApp ? '/checkout-v2' : undefined,
          isLoggedIn: props?.user?.['isLoggedIn'] ?? undefined,
          hasDeviceSubscription: props?.user?.['hasDeviceSubscription'] ?? undefined,
          hasDigitalSubscription: props?.user?.['hasDigitalSubscription'] ?? undefined,
        },
        isAccount,
      );

      const callbackUrl = props?.isNextAuthLoginActive ? NEXT_CALLBACK : CALLBACK;

      loginWithRedirect({
        authorizationParams: {
          connection: Connection[type],
          redirect_uri: addCurrentLocaleToUrl(
            `${window.location.origin}${callbackUrl}`,
            true,
          ),
        },
        appState: toAppState(redirectUrl, app),
      });
    },
    [props?.isNextAuthLoginActive, props?.isSignUpUpdatesActive],
  );

  const setUserCreationFormVisible = (isVisible: boolean) => {
    trackAnalytics(
      track,
      {
        linkName: contentSSO?.emailButtonText.value,
        unitName: AnalyticUnitName[app].email,
        linkTo: isAccount ? APP_MEMBERSHIP_CHECKOUT_ROUTE : '',
        parentType: ParentTypeAnalytic[app],
      },
      isAccount,
    );

    setIsUserCreationFormShown(isVisible);
  };

  return {
    Buttons: (
      <SSOButtons displayTitle={props?.displayTitle}>
        <SocialSignUpButtons
          isEmailWithPasswordFieldsVisible={isUserCreationFormShown}
          showEmailInputHandler={setUserCreationFormVisible}
          signUpUpdatesActive={props?.isSignUpUpdatesActive}
          showLoginSupport={props?.showLoginSupport}
          showOrEmailOption={props?.showOrEmailOption}
          googleButtonClickHandler={ssoButtonHandler(SocialButtonTypes.GOOGLE)}
          appleButtonClickHandler={ssoButtonHandler(SocialButtonTypes.APPLE)}
          mobileAlignment={props?.mobileAlignment ?? 'vertical'}
        />
      </SSOButtons>
    ),
    isUserCreationFormShown,
    isErrorDialogShown,
    ErrorDialog,
    ssoButtonHandler,
    setUserCreationFormVisible,
  };
};
