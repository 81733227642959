import { scroller } from 'react-scroll';
import { useClient } from '@peloton/api';
import { useUserInfo } from '@peloton/auth/UserInfoProvider';
import { useSSOButtons } from '@acme-ui/sso-functionality/hooks/useSSOButtons';
import useCheckout from '@content/client/www/checkout/useCheckout';
import {
  clearPayments,
  getProductIds,
} from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import { isValidatedAddress } from '@ecomm/checkout-commercetools/state/selectors';
import { useSetTriggerVerification } from '@ecomm/checkout-commercetools/state/useTriggerVerification';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { PaymentPriority, ShopPaymentType } from '@ecomm/graphql/types.generated';
import type { ShopPaymentPartner, ShopPayment } from '@ecomm/graphql/types.generated';
import { getOutstandingCartTotal } from '@ecomm/pg-checkout-commercetools/helpers/gift-card-helper';
import toRemainingTotalAmountEligibleForFinancing from '@ecomm/pg-checkout-commercetools/helpers/toRemainingTotalAmountEligibleForFinancing';
import { useUpdateShopCartJupiterMutation } from '@ecomm/shop-cart/graphql/mutations/UpdateCart.generated';
import { checkPostalCodeEligibility } from '@ecomm/shop/api';
import { useUpdateCheckoutForm } from '../components/checkout-section-components/hooks/useUpdateCheckoutForm';
import {
  useErrorMessage,
  useIsPaymentError,
  useSetErrorMessage,
  useSetIsValidationTriggered,
} from '../state/checkoutStore';
import { useMigrationStatus } from '../utils/useMigrationStatus';

export const useGetFinancingPaymentPartners = (): ShopPaymentPartner[] => {
  const { data } = useGetCartItems();
  const isToggleActive = useIsToggleActive();
  const isTruemedEnabled = isToggleActive('truemed_enabled');
  const isCPayEnabled = isToggleActive('cpay_enabled');
  let availableFinancing: ShopPaymentPartner[] = [];
  if (data && data.paymentPartners) {
    availableFinancing = data.paymentPartners.filter((partner: ShopPaymentPartner) => {
      if (partner.type === ShopPaymentType.TruemedHsaFsa) {
        return isTruemedEnabled;
      }

      if (partner.type === ShopPaymentType.CitizensFinancing) {
        return isCPayEnabled;
      }

      return partner.type ? partner.type.includes('Financing') : false;
    });
  }
  const sortedFinancing = availableFinancing.sort((a, b) => {
    if (a.priority === PaymentPriority.Primary && b.priority === PaymentPriority.Backup) {
      return -1;
    }
    if (a.priority === PaymentPriority.Backup && b.priority === PaymentPriority.Primary) {
      return 1;
    }
    return 0;
  });
  return sortedFinancing;
};

export const useIsCreditAvailable = () => {
  const { data } = useGetCartItems();
  if (data && data.paymentPartners) {
    return (
      data.paymentPartners.filter(partner => {
        return partner.type === ShopPaymentType.StripeCreditCard;
      }).length > 0
    );
  }
  return false;
};

export const useIsGiftCardAvailable = () => {
  const { data } = useGetCartItems();
  if (data && data.paymentPartners) {
    return (
      data.paymentPartners.filter(partner => {
        return partner.type === ShopPaymentType.PelotonGiftCard;
      }).length > 0
    );
  }
  return false;
};

export const useCartItems = () => {
  const { data } = useGetCartItems({
    fetchPolicy: 'network-only',
  });

  return data;
};

export const useValidateShippingAddress = () => {
  const cartItems = useCartItems();
  const { trigger } = useUpdateCheckoutForm();
  const { getUser } = useUserInfo();
  const user = getUser();
  const ssoUserEmail = user['email'];
  const { data: shopCart } = useGetCartItems();
  const setTriggerVerification = useSetTriggerVerification();
  const isToggleActive = useIsToggleActive();
  const isNextAuthLoginActive = isToggleActive('nextAuthLoginCheckout');
  const {
    isUserCreationFormShown: isEmailWithPasswordFieldsVisible,
    setUserCreationFormVisible: setIsEmailWithPasswordFieldsVisible,
  } = useSSOButtons('next-www', { isNextAuthLoginActive, user });
  const setIsValidationTriggered = useSetIsValidationTriggered();

  return async () => {
    // Display email field before triggering validation
    if (!ssoUserEmail && !isEmailWithPasswordFieldsVisible) {
      setIsEmailWithPasswordFieldsVisible(true);
    }

    const fieldsToValidate = [
      'email',
      'shippingAddress.firstName',
      'shippingAddress.lastName',
      'shippingAddress.street1',
      'shippingAddress.phone',
      'shippingAddress.city',
      'shippingAddress.state',
      'shippingAddress.postalCode',
    ];

    const isShippingFormValid = await trigger(fieldsToValidate);

    // if the form has errors don't continue
    if (!isShippingFormValid) {
      setIsValidationTriggered(true);
      return false;
    }

    // if the shipping address has not been verified, trigger the address validation modal and don't continue
    if (shopCart && !isValidatedAddress(shopCart)) {
      setTriggerVerification(true);
      return false;
    }
    // if cart does not have email set (seen in case where existing user hasn't logged in)
    if (!cartItems?.customerEmail) {
      return false;
    }

    return isShippingFormValid;
  };
};

export const useCanUserProceedToReviewPage = () => {
  const validateShippingAddress = useValidateShippingAddress();
  const isPaymentError = useIsPaymentError();
  const errorMessage = useErrorMessage();

  return async () => {
    const isFormValid = await validateShippingAddress();
    const isFormValidAndPaymentError = isFormValid && isPaymentError;

    // errorMessage does not neccessarily relate to shipping form validation
    // it may contain postal code errors, payment errors, so it should not be used exclusively to validate shipping address
    // it should be used to determine whether we navigate to the review page
    // if the form is Valid and there is a paymentError we are still letting user proceed to the review order.
    if (isFormValidAndPaymentError) {
      return true;
    }
    return isFormValid && !errorMessage;
  };
};

export const useAllProductsPostalCodeEligible = () => {
  const client = useClient();
  const cartItems = useCartItems();
  const cartItemList = cartItems?.lineItems ?? [];

  /**
   * @param postalCode Postal code to check eligibility
   * @returns boolean
   *
   * This function checks if all products in the cart are eligible for the given postal code
   */
  return async (postalCode: string) => {
    const productIdArray = getProductIds(cartItemList ?? []);
    for (const productId of productIdArray) {
      const isEligible = await checkPostalCodeEligibility(client, productId, postalCode);
      if (!isEligible) {
        return false;
      }
    }
    return true;
  };
};

export const useCanUserAddPaymentDetails = () => {
  const validateShippingAddress = useValidateShippingAddress();
  const setErrorMessage = useSetErrorMessage();
  const allProductsPostalCodeEligible = useAllProductsPostalCodeEligible();
  const { data: shopCart } = useGetCartItems();
  const { content: checkoutContent } = useCheckout();

  return async () => {
    const isValidAddress = await validateShippingAddress();
    let isPostalCodeIneligible = false;

    if (isValidAddress) {
      const isAllProductsPostalCodeEligible = await allProductsPostalCodeEligible(
        shopCart?.shippingAddress?.postalCode ?? '',
      );
      if (!isAllProductsPostalCodeEligible) {
        setErrorMessage(checkoutContent?.postalCodeDoesNotExist?.value ?? '');
        isPostalCodeIneligible = true;

        const elementName = 'ScrollToBanner';
        scroller.scrollTo(elementName, {
          duration: 500,
          smooth: 'easeInOutQuad',
        });
      }
    }

    return isValidAddress && !isPostalCodeIneligible;
  };
};

export const useEligibleFinancingPartners = () => {
  const cartItems = useCartItems();
  const availableFinancingPartners = useGetFinancingPaymentPartners();
  const remainingTotal = getOutstandingCartTotal(cartItems);
  const financingPartners = availableFinancingPartners.filter(partner => {
    return (
      partner.type &&
      toRemainingTotalAmountEligibleForFinancing(remainingTotal, partner.type)
    );
  });

  return financingPartners;
};

export const useShowFinancing = () => {
  const cartItems = useCartItems();
  const financingPartners = useGetFinancingPaymentPartners();
  const isFinancingEligible = financingPartners.length > 0;
  const outstandingCartTotal = getOutstandingCartTotal(cartItems);

  return isFinancingEligible && outstandingCartTotal > 0;
};

export const useShowGiftCard = () => {
  const isGiftCardAvailable = useIsGiftCardAvailable();
  const { CTGiftCardEnabled } = useMigrationStatus();

  return isGiftCardAvailable && CTGiftCardEnabled;
};

export const useClearPayments = () => {
  const [updateCartMutation] = useUpdateShopCartJupiterMutation();
  const { data } = useGetCartItems();
  const payments = data?.payments ?? [];

  return () => {
    clearPayments(payments as ShopPayment[], updateCartMutation);
  };
};
