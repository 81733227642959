import type { ParsedUrlQuery } from 'querystring';
import { currentHostname } from '@peloton/browser/identify';
import type { UatHostnameEnv } from '@peloton/env-hostnames/toHostnameEnv';
import { toHostnameEnv } from '@peloton/env-hostnames/toHostnameEnv';
import environmentFlags from '@peloton/env/environmentFlags';
import { isDeployPreview } from '@peloton/env/models';
import { useExtLinkEnv } from '@peloton/external-links/context/Provider';
import { toExtLinkEnv } from '@peloton/external-links/models';
import toRedirectUrl from '@peloton/external-links/toRedirectUrl';
import {
  REDIRECT_APP_QUERY_PARAM,
  REDIRECT_URL_QUERY_PARAM,
} from '@peloton/external-links/urls';
import { addLocaleToRelativeUrl } from '@peloton/internationalize/addCurrentLocaleToUrl';
import { extLinkEnvMatcher, loginUrlMatcher } from './constants';

export const toAppState = (redirectUrl: string, app: string) => ({
  [REDIRECT_URL_QUERY_PARAM]: btoa(redirectUrl),
  [REDIRECT_APP_QUERY_PARAM]: app,
});

export const useRedirectUrl = (app: string) => {
  const extLinkEnv = useExtLinkEnv();

  if (typeof window === 'undefined') {
    return () => '';
  }

  const envFlags = environmentFlags(app)();
  return (path: string, type: string = app) => {
    if (envFlags.isLocal) {
      const test = toRedirectUrl({
        extLinkEnv: toExtLinkEnv({ [app]: extLinkEnvMatcher[app].Local }),
        path,
        type,
      });

      return test;
    }
    const redirectUrl = toRedirectUrl({ extLinkEnv, path, type });
    if (isDeployPreview(currentHostname)) {
      const hostNameEnv = toHostnameEnv(currentHostname) as UatHostnameEnv;
      if (hostNameEnv.urlApp) {
        return redirectUrl.replace(
          `${type}`,
          `${hostNameEnv.instance}--${hostNameEnv.urlApp}`,
        );
      }
      return redirectUrl.replace(`${type}`, `${hostNameEnv.instance}--${type}`);
    }

    return redirectUrl;
  };
};

export const toLoginRoute = (redirectUrlParam: string, path: string) =>
  `${path}?${REDIRECT_URL_QUERY_PARAM}=${btoa(redirectUrlParam)}`;
export const useLoginUrl = (app: string) => {
  const redirectUrl = useRedirectUrl(app);
  return (redirectUrlParam: string) =>
    redirectUrl(toLoginRoute(redirectUrlParam, loginUrlMatcher[app]));
};

export const queryObjToString = (query: ParsedUrlQuery): string => {
  const queryStr = new URLSearchParams(query as {}).toString();
  return queryStr ? `?${queryStr}` : '';
};

export const getRedirectPath = ({
  pathname,
  slug,
  promoSlug,
  queryStr,
}: {
  pathname: string;
  queryStr: string;
  slug?: string;
  promoSlug?: string;
}) => {
  let url = pathname;

  if (slug && !promoSlug && !pathname.includes(slug)) {
    url += `/${slug}`;
  }

  return addLocaleToRelativeUrl(`${url}${queryStr}`);
};
