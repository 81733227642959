import type {
  CartItemNext,
  PrepaidMembershipCreditsItem,
} from '@ecomm/graphql/types.generated';

export type CartItemNextType = CartItemNext & {
  __typename?: string;
  bundleId?: string;
  productOptionId?: string;
  products?: any[];
  productId?: string;
  productLine?: string;
  slug?: string;
  sku?: string;
  bundleSlug?: string;
};

export const isBundleItem = (item: Partial<CartItemNextType>) =>
  ['CfuBundleItem', 'AccessoryBundleItem', 'LeasingBundleItem'].includes(
    item.__typename ?? '',
  );

export const isPrepaidCreditItem = (
  item: Partial<CartItemNextType>,
): item is PrepaidMembershipCreditsItem =>
  item.__typename === 'PrepaidMembershipCreditsItem';

export const getBundleItems = (item: Partial<CartItemNextType>) =>
  (isBundleItem(item) ? item.products : []) ?? [];

export const optionId = (item: Partial<CartItemNextType>) =>
  (isBundleItem(item) ? item.id : item.productOptionId) ?? '';
