import { useLocale, toCountryFromLocale, toCurrency } from '@peloton/internationalize';
import { Locale } from '@peloton/internationalize/models/locale';
import { useCommercetoolsClient } from '../apollo';
import { CT_ERRORS } from '../constants';
import type { ProductCatalogType, UseQueryResult } from '../models/main';
import type { useSearchBundleByKeyQuery } from '../queries/SearchBundleByKey.generated';
import type { useSearchProductByKeyQuery } from '../queries/SearchProductByKey.generated';
import { apolloErrorHandler } from '../utils/apolloErrorHandler';

type UseSearchAccessoryByKeyQuery =
  | typeof useSearchBundleByKeyQuery
  | typeof useSearchProductByKeyQuery;

type SearchAccessoryResultMapper<T> = (product: ProductCatalogType, locale: Locale) => T;

export const useSearchProductByKey = <T>(
  key: string,
  useQuery: UseSearchAccessoryByKeyQuery,
  mapper: SearchAccessoryResultMapper<T>,
  categoryId?: string,
): UseQueryResult<T> => {
  const locale = useLocale();
  const country = toCountryFromLocale(locale);
  const acceptLanguage = [locale, Locale.Default];
  const client = useCommercetoolsClient();

  const { loading, data, error } = useQuery({
    fetchPolicy: 'cache-first',
    variables: {
      where: `key="${key}" and masterData(current(categories(id="${categoryId}")))`,
      acceptLanguage,
      currency: toCurrency(country),
      country,
    },
    client,
    notifyOnNetworkStatusChange: true,
    skip: !categoryId,
  });

  const products = data?.products.results;
  const keyProduct =
    products && products.length > 0 ? (products[0] as ProductCatalogType) : undefined;
  const resultData = keyProduct ? mapper(keyProduct, locale) : undefined;
  const notFoundError =
    products?.length === 0 ? apolloErrorHandler(CT_ERRORS.PRODUCT_NOT_FOUND) : undefined;

  return {
    loading,
    error: error || notFoundError,
    data: resultData,
  };
};
