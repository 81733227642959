import { Locale, toLocaleFromTLD } from '@peloton/internationalize/models/locale';
import type { Product } from '@ecomm/graphql/types.generated';

export const BIKE_RENTAL_SLUG = 'opc-pkg-7';
export const BIKE_WITH_DELIVERY_RENTAL_SLUG = 'opc-pkg-7-dlv250';
export const BIKE_PLUS_RENTAL_SLUG = 'opc-pkg-bp-8';
export const BIKE_PLUS_WITH_DELIVERY_RENTAL_SLUG = 'opc-pkg-bp-8-250';
export const PREVIOUS_BIKE_PLUS_RENTAL_SLUG = 'opc-pkg-bp-7';

export const INTL_BIKE_RENTAL_SLUG = 'faas-pkg-intl-1';
export const INTL_BIKE_PLUS_RENTAL_SLUG = 'faas-pkg-intl-bp-1';

export const RENTAL_SLUGS = [
  BIKE_RENTAL_SLUG,
  BIKE_WITH_DELIVERY_RENTAL_SLUG,
  BIKE_PLUS_RENTAL_SLUG,
  BIKE_PLUS_WITH_DELIVERY_RENTAL_SLUG,
  INTL_BIKE_RENTAL_SLUG,
  INTL_BIKE_PLUS_RENTAL_SLUG,
  PREVIOUS_BIKE_PLUS_RENTAL_SLUG,
];

export const RENTALS_SHOP_PATH = '/shop/opc';

export const getRentalSlugs = () => {
  const locale = toLocaleFromTLD();

  switch (locale) {
    case Locale.EnglishUnitedStates:
      return {
        bikeSlug: BIKE_RENTAL_SLUG,
        bikeWithDeliverySlug: BIKE_WITH_DELIVERY_RENTAL_SLUG,
        bikePlusSlug: BIKE_PLUS_RENTAL_SLUG,
        bikePlusWithDeliverySlug: BIKE_PLUS_WITH_DELIVERY_RENTAL_SLUG,
      };
    case Locale.EnglishCanada:
      return {
        bikeSlug: BIKE_RENTAL_SLUG,
        bikePlusSlug: BIKE_PLUS_RENTAL_SLUG,
      };
    case Locale.GermanGermany:
      return {
        bikeSlug: INTL_BIKE_RENTAL_SLUG,
        bikePlusSlug: INTL_BIKE_PLUS_RENTAL_SLUG,
      };
    case Locale.EnglishUnitedKingdom:
      return {
        bikeSlug: INTL_BIKE_RENTAL_SLUG,
        bikePlusSlug: INTL_BIKE_PLUS_RENTAL_SLUG,
      };
    default:
      return {
        bikeSlug: BIKE_RENTAL_SLUG,
        bikePlusSlug: BIKE_PLUS_RENTAL_SLUG,
      };
  }
};

export const isRentalSlug = (slug: string) => RENTAL_SLUGS.includes(slug);

export const isRentalItem = (item: Product) =>
  item.slug.startsWith('opc') || item.slug.startsWith('faas');
