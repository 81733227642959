import { spacing } from '@pelotoncycle/design-system';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {
  LOGIN_SOCIAL_ERROR_CODE,
  RETURN_TO_STORAGE_KEY,
  socialErrorCodesDictionary,
} from '@peloton/auth/constants';
import { useOauth } from '@peloton/auth/OauthProvider';
import { usePathname, useQueryParams } from '@peloton/hooks';
import useLocalStorage from '@peloton/hooks/shared/useLocalStorage';
import { useSSOContent } from '@content/client/www/sso/useSSOContent';
import SocialSignUpButton from '../components/SocialSignUpButtons/SocialSignUpButton/SocialSignUpButton';
import UserExistsModal from '../components/UserExistsModal/UserExistsModal';
import {
  SocialButtonTypes,
  ACCOUNT_EXISTS_ERROR_CODE_TYPE,
  Connection,
} from '../constants';
import { useLoginUrl, toAppState, queryObjToString } from '../helpers';

const useSocialLinkingErrorModal = (app: string) => {
  const { loginWithRedirect, logout } = useOauth();
  const pathname = usePathname();
  const query = useQueryParams();
  const { content: contentSSO } = useSSOContent();
  const loginUrl = useLoginUrl(app);
  const [, setReturnTo, removeReturnTo] = useLocalStorage(RETURN_TO_STORAGE_KEY, '');
  const [errorCode, , removeErrorCode] = useLocalStorage(LOGIN_SOCIAL_ERROR_CODE, '');
  const socialSignUpType: Exclude<SocialButtonTypes, SocialButtonTypes.EMAIL> =
    ACCOUNT_EXISTS_ERROR_CODE_TYPE[errorCode!];

  const errorCodeDictionaryKey = socialErrorCodesDictionary[errorCode!];

  const errorDescription =
    contentSSO &&
    errorCodeDictionaryKey &&
    contentSSO[`socialLinkingErrors${[errorCode]}`].value;

  const modalHeading = Object.keys(ACCOUNT_EXISTS_ERROR_CODE_TYPE).includes(errorCode!)
    ? contentSSO?.accountExistsModalHeading.value
    : '';

  const isErrorDialogShown = !!errorCode && !!errorDescription;

  useEffect(() => {
    removeReturnTo();
    const cleanup = () => {
      removeErrorCode();
    };
    window.addEventListener('beforeunload', cleanup);
    return () => {
      window.removeEventListener('beforeunload', cleanup);
    };
  }, []);

  const appleButtonText = contentSSO?.appleButtonText.value;
  const googleButtonText = contentSSO?.googleButtonText.value;

  const signUpButtonText =
    socialSignUpType === SocialButtonTypes.APPLE ? appleButtonText : googleButtonText;
  const queryStr = queryObjToString(query);
  const redirectUrl = useMemo(() => `${pathname}${queryStr}`, [pathname, queryStr]);

  const handleSetReturnTo = useCallback(() => {
    setReturnTo(redirectUrl);
  }, [setReturnTo, redirectUrl]);

  const handlerOnCloseModal = useCallback(() => {
    handleSetReturnTo();
    logout(
      {
        logoutParams: {
          returnTo: loginUrl(window.location.href),
        },
      },
      true,
    );
  }, [handleSetReturnTo, logout, loginUrl]);

  const handleSignUpButtonClick = useCallback(() => {
    handleSetReturnTo();

    loginWithRedirect({
      authorizationParams: {
        connection: Connection[socialSignUpType],
      },
      appState: toAppState(redirectUrl, app),
    });
  }, [handleSetReturnTo, loginWithRedirect, socialSignUpType, redirectUrl, app]);

  return {
    isErrorDialogShown,
    ErrorDialog: (
      <UserExistsModal
        errorDescription={errorDescription}
        heading={modalHeading}
        handlerOnClose={handlerOnCloseModal}
        signUpButton={
          socialSignUpType && (
            <SocialButtonWrapper>
              <SocialSignUpButton
                buttonClickHandler={handleSignUpButtonClick}
                buttonText={signUpButtonText}
                type={socialSignUpType}
                mobileAlignment={'vertical'}
              />
            </SocialButtonWrapper>
          )
        }
      />
    ),
  };
};

const SocialButtonWrapper = styled.div`
  max-height: ${spacing[56]};
`;

export default useSocialLinkingErrorModal;
