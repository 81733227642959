import { Button } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';

export type Props = {
  showEmailInputHandler: (value: boolean) => void;
  isEmailWithPasswordFieldsVisible: boolean;
  emailButtonText?: string;
  dataTestId?: string;
};

const EmailSignUpButton: React.FC<React.PropsWithChildren<Props>> = ({
  showEmailInputHandler,
  isEmailWithPasswordFieldsVisible,
  emailButtonText,
  dataTestId,
}) => {
  const toggleEmailInput = () => {
    showEmailInputHandler(!isEmailWithPasswordFieldsVisible);
  };

  return (
    <EmailSignUpButtonContent
      onClick={toggleEmailInput}
      variant="outline"
      color="dark"
      data-test-id={dataTestId}
    >
      {emailButtonText}
    </EmailSignUpButtonContent>
  );
};

export default EmailSignUpButton;

const EmailSignUpButtonContent = styled(Button)`
  width: 100%;
  flex: 1;
`;
