import { useHasVariation } from '@peloton/split-testing/hooks';

const CHECKOUT_UX_REFRESH_EXPERIMENT = 'Checkout UX Refresh';
const CHECKOUT_UX_REFRESH_VARIATION = 'Variation #1';

const useIsCheckoutUxRefreshVariationActive = () => {
  return useHasVariation(CHECKOUT_UX_REFRESH_EXPERIMENT, CHECKOUT_UX_REFRESH_VARIATION);
};

export default useIsCheckoutUxRefreshVariationActive;
