import { Icon, Label, spacing } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import useCartContent from '@ecomm/cart-next/shared/useCartContent';
import { Partners } from '@ecomm/financing/models/Partners';
import { Link } from '@ecomm/internationalize-ui';
import type { BundleType } from '@ecomm/shop/models';
import TruemedModal from './TruemedModal';

type Props = {
  bundleType: BundleType;
  price: number;
};

const TruemedCopy: React.FC<React.PropsWithChildren<Props>> = ({ bundleType, price }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const financingPricingTruemedText = useCartContent('financingPricingTruemedText');
  const financingPricingTruemed = useCartContent('financingPricingTruemed');
  const { trackEvent } = useTracking();

  const handleModalOpen = () => {
    setIsModalOpen(true);
    trackEvent({
      event: 'Clicked Prequalify CTA',
      properties: {
        pageName: getCurrentRelativePathname(),
        price,
        productName: bundleType,
        unitName: 'cart',
        billingPartner: Partners.Truemed,
      },
    });
  };

  return (
    <>
      <StyledText>{financingPricingTruemedText}</StyledText>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link variant="primary" onClick={handleModalOpen}>
        <Label size="medium">{financingPricingTruemed}</Label>
        <StyledIconWrapper>
          <Icon name="trueMed" height={12} />
        </StyledIconWrapper>
      </Link>
      <TruemedModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </>
  );
};

export default TruemedCopy;

const StyledText = styled.div`
  margin-top: ${spacing[24]};
  margin-bottom: ${spacing[8]};
`;

const StyledIconWrapper = styled.div`
  margin-left: 6px;
  display: inline-block;
`;
