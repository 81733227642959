import { Eyebrow, Flex, Support, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import Markdown from '@peloton/copy/Markdown';
import { media } from '@peloton/styles';
import { useSSOContent } from '@content/client/www/sso/useSSOContent';
import EmailSignUpButton from './EmailSignUpButton/EmailSignUpButton';
import OrText from './OrText/OrText';
import SocialSignUpButton from './SocialSignUpButton/SocialSignUpButton';

export type Props = {
  showEmailInputHandler: (value: boolean) => void;
  showLoginSupport?: boolean;
  signUpUpdatesActive?: boolean;
  isEmailWithPasswordFieldsVisible: boolean;
  showOrEmailOption?: boolean;
  googleButtonClickHandler: () => void;
  appleButtonClickHandler: () => void;
  mobileAlignment: 'vertical' | 'horizontal';
};

const SocialSignUpButtons: React.FC<React.PropsWithChildren<Props>> = ({
  showEmailInputHandler,
  signUpUpdatesActive,
  showLoginSupport,
  isEmailWithPasswordFieldsVisible,
  showOrEmailOption = true,
  googleButtonClickHandler,
  appleButtonClickHandler,
  mobileAlignment,
}) => {
  const { content: contentSSO } = useSSOContent();
  const emailButtonText = contentSSO?.emailButtonText.value;
  const emailSupportText = contentSSO?.emailButtonSupport?.value;

  const SocialSignUpButtonsContent =
    mobileAlignment == 'vertical'
      ? SocialSignUpButtonsContentVertical
      : SocialSignUpButtonsContentHorizontal;

  return (
    <SocialSignUpButtonsContent>
      <SocialSignUpButton
        type="apple"
        buttonText={contentSSO?.appleButtonText.value}
        buttonClickHandler={appleButtonClickHandler}
        dataTestId="appleSocialSignupButton"
        mobileAlignment={mobileAlignment}
      />
      <SocialSignUpButton
        type="google"
        buttonText={contentSSO?.googleButtonText.value}
        buttonClickHandler={googleButtonClickHandler}
        dataTestId="googleSocialSignupButton"
        mobileAlignment={mobileAlignment}
      />
      {showOrEmailOption && (
        <>
          <OrText orText={contentSSO?.orText.value} />
          {signUpUpdatesActive ? (
            <Flex flexDirection="column" gap={spacing[16]}>
              <Eyebrow size="medium">{emailButtonText}</Eyebrow>
              {showLoginSupport && (
                <Support size="medium">
                  <Markdown content={emailSupportText} />
                </Support>
              )}
            </Flex>
          ) : (
            <EmailSignUpButton
              showEmailInputHandler={showEmailInputHandler}
              isEmailWithPasswordFieldsVisible={isEmailWithPasswordFieldsVisible}
              emailButtonText={emailButtonText}
              dataTestId="emailSocialSignupButton"
            />
          )}
        </>
      )}
    </SocialSignUpButtonsContent>
  );
};

export default SocialSignUpButtons;

const SocialSignUpButtonsContentHorizontal = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[16]};
  ${media.desktopLarge`
    align-items: start;
  `};
`;

const SocialSignUpButtonsContentVertical = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: ${spacing[16]};
  ${media.desktop`
    flex-direction: row;
  `};
  ${media.desktopLarge`
    align-items: start;
  `};
`;
