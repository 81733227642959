import React from 'react';
import type { IconProps } from './types';

const GoogleSocialIcon: React.FC<React.PropsWithChildren<IconProps>> = props => (
  <svg role="presentation" viewBox="0 0 20 20" {...props}>
    <path
      d="M10.2203 4.09091C11.59 4.09091 12.8273 4.59091 13.7993 5.40909L16.6273 2.5C14.904 0.954545 12.6947 0 10.2203 0C6.37609 0 3.06214 2.27273 1.51562 5.59091L4.78539 8.18182C5.53656 5.77273 7.65749 4.09091 10.2203 4.09091Z"
      fill="#EA4335"
    />
    <path
      d="M0.5 9.99996C0.5 11.5909 0.853488 13.0909 1.51628 14.409L4.78605 11.8181C4.56512 11.2727 4.47674 10.6363 4.47674 9.99996C4.47674 9.36359 4.56512 8.72723 4.78605 8.13632L1.51628 5.54541C0.853488 6.90905 0.5 8.40905 0.5 9.99996Z"
      fill="#FBBC05"
    />
    <path
      d="M10.2203 20.0001C12.6063 20.0001 14.8598 19.1365 16.5389 17.5001L13.4459 15.0456C12.5621 15.6365 11.4575 15.9092 10.2203 15.9092C7.65749 15.9092 5.53656 14.2274 4.78539 11.8638L1.51562 14.4547C3.06214 17.7274 6.37609 20.0001 10.2203 20.0001Z"
      fill="#34A853"
    />
    <path
      d="M19.4998 10.0001C19.4998 9.40916 19.4114 8.77279 19.2788 8.18188H10.2207V12.0455H15.4347C15.1695 13.3637 14.4626 14.3637 13.4463 15.0455L16.5393 17.5001C18.3509 15.8182 19.4998 13.2728 19.4998 10.0001Z"
      fill="#4285F4"
    />
  </svg>
);

export default GoogleSocialIcon;
