import React from 'react';
import type { IconProps } from './types';

const AppleSocialIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  fill = 'white',
  ...props
}) => (
  <svg role="presentation" viewBox="0 0 18 20" {...props}>
    <path
      d="M14.7133 10.5689C14.7043 8.9302 15.454 7.69336 16.9716 6.78248C16.1225 5.58137 14.8398 4.92054 13.1461 4.79105C11.5428 4.66603 9.79038 5.71533 9.14904 5.71533C8.47157 5.71533 6.91791 4.8357 5.69846 4.8357C3.17827 4.87589 0.5 6.82267 0.5 10.7832C0.5 11.9531 0.716791 13.1616 1.15037 14.4089C1.72848 16.0476 3.81509 20.0662 5.99203 19.9992C7.13018 19.9724 7.93411 19.1999 9.41551 19.1999C10.8518 19.1999 11.597 19.9992 12.8661 19.9992C15.0611 19.9679 16.949 16.3155 17.5 14.6723C14.5553 13.3015 14.7133 10.6537 14.7133 10.5689ZM12.157 3.2372C13.39 1.7905 13.2771 0.4733 13.241 0C12.1525 0.0625114 10.8924 0.732276 10.1743 1.55832C9.3839 2.44241 8.9187 3.53636 9.01807 4.76872C10.1969 4.85803 11.2718 4.2597 12.157 3.2372Z"
      fill={fill}
    />
  </svg>
);

export default AppleSocialIcon;
