import useEntry from '@content/client/hooks/useEntry';
import { client } from '@content/client/peloContentClient';

// TODO: set types in many places
type FormatProps = {
  address: any;
  country: any;
  phone?: string;
};

type CompareTypes = {
  cart?: Record<string, any> | null;
  form: Record<string, any> | null;
};

export const formatAddress = ({ address, country, phone }: FormatProps) => {
  if (Boolean(address)) {
    return {
      firstName: address.firstName,
      lastName: address.lastName,
      city: address.city,
      country,
      phone,
      postalCode: address.postalCode,
      state: address.state,
      street1: address.street1,
      street2: address.street2,
    };
  } else {
    return {
      country,
    };
  }
};

type CheckoutStepUIProps = {
  isComplete: boolean;
  isOpen: boolean;
};

export const cartAndFormDataMatch = ({ cart, form }: CompareTypes) => {
  // Check if both are null
  if (!cart && !form) {
    return true;
  }

  // If one is null and the other is not, return false
  if (!cart || !form) {
    return false;
  }

  // Compare the properties of both objects
  return Object.keys(form).every(key => cart[key] === form[key]);
};

export const useGetContentfulData = (contentfulId: string) => {
  const { data } = useEntry(client, contentfulId);
  return data?.fields?.value ?? '';
};

export const isCheckoutStepCompleteAndClosed = ({
  isComplete,
  isOpen,
}: CheckoutStepUIProps) => !isOpen && isComplete;
