import { toLocaleFromTLD } from '@peloton/internationalize/models/locale';
import {
  toCTCategory,
  toCTSegmentProducts,
} from '@ecomm/cart-next/analytics/segmentMappings';
import {
  ACCESSORY,
  BUNDLE,
  GUIDE,
} from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import { toCurrencyfromLocale } from '@ecomm/graphql-bridge';
import type { PaymentPartners } from '@ecomm/pg-checkout-commercetools/analytics/useCtCheckoutStepViewed';
import { RENTAL_SLUGS } from '@ecomm/rentals/models';
import type {
  CtCartFragment,
  LineItemDataFragment,
} from '@ecomm/shop-cart/graphql/fragments.generated';

enum PaymentToBilling {
  'Credit Card' = 'none',
  'Google Pay' = 'none',
  'affirm' = 'financing',
  'citizens' = 'financing',
  'Klarna' = 'financing',
  'zip' = 'financing',
}

export const toStepName = (step: number) =>
  ({
    1: 'Viewed Checkout Page',
    2: 'Started Entering Shipping Information',
    3: 'Selected Payment Option (CC or Financing Selection)',
    4: '(CC only + UK and CA) Viewed Order Review Page',
    5: 'Viewed Checkout Payment Screen',
    6: 'Address Confirmation Modal Opened',
    7: 'User Moves away from Email Address Field',
    8: 'Entered Shipping Information + Tax/Shipping Calculated',
    9: 'Account Info Module',
    10: 'Cross Sell Module',
    11: 'Shipping Module',
  }[step]);

export const billingPartner = (Payment: PaymentPartners) => {
  return PaymentToBilling[Payment];
};

export const hasCpo = (lineItem: LineItemDataFragment[]) =>
  Boolean(lineItem.some(item => RENTAL_SLUGS.includes(item?.productVariant?.key || '')));

const getSingleAccessories = (items?: LineItemDataFragment[] | null) => {
  const accessories = !items
    ? []
    : items
        .filter(item => item.productVariant?.type === ACCESSORY)
        .map(({ id, productId, productVariant, priceSummary, quantity, productKey }) => ({
          Id: id,
          productId: productId,
          Description: productVariant?.description ?? '',
          Images: productVariant?.images?.map(image => image.url) ?? [],
          name: productVariant?.name ?? '',
          price: priceSummary?.subtotal?.centAmount ?? 0,
          // currently working with team to figure out how to access productLine.
          productLine: '',
          quantity: quantity ?? 0,
          Sku: productVariant?.sku ?? '',
          Slug: productKey,
          Type: productVariant?.type ?? '',
        }));
  return accessories;
};

const getBundleAccesssories = (items: LineItemDataFragment[]) => {
  const accessories = items
    .filter(
      item =>
        item?.productVariant?.type === BUNDLE &&
        item?.productVariant?.legacyEquipmentType === null,
    )
    .map(({ id, bundleItemLineItems, productVariant }) => ({
      Id: id,
      description: productVariant?.description || '',
      Images: productVariant?.images?.map(image => image.url) || [],
      items: getSingleAccessories(bundleItemLineItems) || {},
    }));
  return accessories;
};

export const getAllAccessories = (lineItems: LineItemDataFragment[]) => {
  const singleAccessories = getSingleAccessories(lineItems);
  const bundleAccessories = getBundleAccesssories(lineItems);

  return [...bundleAccessories, ...singleAccessories];
};

export const getCartSubTotal = (cartItems?: CtCartFragment | null) =>
  cartItems?.priceSummaries?.cartPriceSummary?.subtotal?.centAmount ?? 0;

const toFormattedPrice = (
  priceInCents: number,
  locale: string,
  currency: string,
): string => {
  return (priceInCents / 100).toLocaleString(locale, {
    style: 'currency',
    currency: currency,
  });
};

export const hasAccessories = (cartItemList: LineItemDataFragment[]): boolean => {
  return cartItemList?.some(
    (item: LineItemDataFragment) =>
      item?.productVariant?.type === ACCESSORY ||
      hasAccessories(item?.bundleItemLineItems ?? []),
  );
};

type BundleProduct = {
  name: string;
  sku: string;
  image: string;
};

export type OrderItem = {
  name: string;
  price: string;
  price_in_cents: number;
  description: string;
  category: string;
  image: string;
  bundle_products?: BundleProduct[];
};

export const getOrderItems = (lineItem: LineItemDataFragment[]) => {
  const orderItems: OrderItem[] = [];

  const locale = toLocaleFromTLD();
  const currency = toCurrencyfromLocale(locale);

  lineItem.forEach(item => {
    const priceInCents = item?.priceSummary?.subtotal?.centAmount;

    const orderItem: OrderItem = {
      name: item?.productVariant?.name ?? '',
      price: toFormattedPrice(priceInCents, locale, currency),
      description: item?.productVariant?.description ?? '',
      category: toCTCategory(lineItem),
      image: item?.productVariant?.images?.[0]?.url ?? '',
      price_in_cents: priceInCents,
    };

    if (item.bundleItemLineItems !== null) {
      orderItem.bundle_products = [];

      item?.bundleItemLineItems?.forEach(bundleItem => {
        orderItem.bundle_products?.push({
          name: bundleItem?.productVariant?.name ?? '',
          sku: bundleItem?.productVariant?.sku ?? '',
          image: bundleItem?.productVariant?.images[0]?.url ?? '',
        });
      });
    }

    orderItems.push(orderItem);
  });

  return orderItems;
};

export const getPrepaidSubscriptionDuration = (prepaidAam?: LineItemDataFragment) => {
  return prepaidAam?.productVariant?.size || null;
};

export const getBundleNames = (itemList: LineItemDataFragment[]) => {
  return itemList
    .filter(item => item?.productVariant?.type === BUNDLE)
    .map(item => item.productVariant?.name);
};

export const hasAccessoryBundle = (itemList: LineItemDataFragment[]) => {
  return itemList.some(item => {
    return item?.bundleItemLineItems && !item?.productVariant?.legacyEquipmentType;
  });
};

export const getAccessoryNames = (itemList: LineItemDataFragment[]) => {
  return itemList
    .filter(item => {
      return item?.productVariant?.type === ACCESSORY;
    })
    .map(item => item?.productVariant?.name);
};

export const hasOpc = (itemList: LineItemDataFragment[]) => {
  return Boolean(
    itemList.some(item => RENTAL_SLUGS.includes(item?.productVariant?.key || '')),
  );
};

export const hasAccessory = (itemList: LineItemDataFragment[]) => {
  return Boolean(itemList.some(item => item?.productVariant?.type === ACCESSORY));
};

export const toCheckboxEvent = (
  eventName: string,
  isSelected: boolean,
  position: 'cart' | 'checkout',
  shopCartData?: CtCartFragment | null,
) => {
  const items = shopCartData?.lineItems ?? [];
  const category = toCTCategory(items);
  const hasGuide = Boolean(category.includes(GUIDE));

  let bundleName = getBundleNames(items);

  if (hasGuide) {
    bundleName = [...(bundleName || ''), 'Guide'];
  }
  const slugs = items.map(item => item.productKey);

  return {
    event: eventName,
    properties: {
      cartId: shopCartData?.id,
      category,
      hasTradeIn: false,
      products: toCTSegmentProducts(items),
      hasAccessory: hasAccessory(items),
      hasGuide,
      hasAccessoryBundle: hasAccessoryBundle(items),
      accessoryName: getAccessoryNames(items),
      bundleName,
      hasCPO: hasCpo(items),
      hasOPC: hasOpc(items),
      selected: isSelected,
      position,
      slug: slugs,
    },
  };
};
