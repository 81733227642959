import { Container } from '@pelotoncycle/design-system';
import React from 'react';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { Partners } from '@ecomm/financing/models/Partners';
import { ShopPaymentType } from '@ecomm/graphql/types.generated';
import AlternativePartnerContainer from '@ecomm/pg-checkout-commercetools/components/financing/AlternativePartnerContainer';
import { useGetFinancingPaymentPartners } from '../../hooks/payments';
import FinancingSupportContainer from './FinancingSupportContainer';

type Props = { className?: string };

const Financing: React.FC<React.PropsWithChildren<Props>> = ({ className }) => {
  const { data: shopCart } = useGetCartItems();
  const paymentPartners = useGetFinancingPaymentPartners();
  const isTruemedGuideEnable = useIsToggleActive()('truemed_guide_enabled');

  const isCartFinanceable = shopCart?.isFinanceable ?? false;

  let primaryPaymentPartner = paymentPartners.find(
    partner => partner.type && toFinancingPartnerPriority(partner.type) == 1,
  );
  let secondaryPaymentPartner = paymentPartners.find(
    partner => partner.type && toFinancingPartnerPriority(partner.type) == 2,
  );
  const alternativePartner = paymentPartners.find(
    partner => partner.type && toFinancingPartnerPriority(partner.type) == 3,
  );

  // In event that only secondary financing partner is available, set as primary.
  if (!primaryPaymentPartner && secondaryPaymentPartner) {
    primaryPaymentPartner = secondaryPaymentPartner;
    secondaryPaymentPartner = undefined;
  }

  const alternativePaymentPartner = alternativePartner?.type
    ? toFinancingPartnerFromShopPaymentType(alternativePartner.type)
    : null;

  if (!isCartFinanceable || !primaryPaymentPartner?.type) {
    if (alternativePartner && isTruemedGuideEnable) {
      return (
        <AlternativePartnerContainer
          className={className}
          alternativePartner={alternativePaymentPartner}
        />
      );
    }

    return null;
  }

  const primaryFinancingPartner = toFinancingPartnerFromShopPaymentType(
    primaryPaymentPartner.type,
  );

  const secondaryFinancingPartner = secondaryPaymentPartner?.type
    ? toFinancingPartnerFromShopPaymentType(secondaryPaymentPartner.type)
    : null;

  if (!primaryFinancingPartner) {
    return null;
  }
  return (
    <Container className={className}>
      <FinancingSupportContainer
        financingPartner={primaryFinancingPartner}
        secondaryFinancingPartner={secondaryFinancingPartner}
        alternativePaymentPartner={alternativePaymentPartner}
      />
    </Container>
  );
};

export default Financing;

const toFinancingPartnerFromShopPaymentType = (
  paymentType: ShopPaymentType,
): Partners | undefined => {
  switch (paymentType) {
    case ShopPaymentType.AffirmFinancing:
      return Partners.Affirm;
    case ShopPaymentType.CitizensFinancing:
      return Partners.Citizens;
    case ShopPaymentType.KlarnaFinancing:
      return Partners.Klarna;
    case ShopPaymentType.ZipFinancing:
      return Partners.Zip;
    case ShopPaymentType.TruemedHsaFsa:
      return Partners.Truemed;
    default:
      return undefined;
  }
};

// This priority determines which appears on top of the other in cart flyout
const toFinancingPartnerPriority = (paymentType: ShopPaymentType): number => {
  switch (paymentType) {
    case ShopPaymentType.TruemedHsaFsa:
      return 3;
    case ShopPaymentType.AffirmFinancing:
      return 2;
    case ShopPaymentType.CitizensFinancing:
    case ShopPaymentType.KlarnaFinancing:
    case ShopPaymentType.ZipFinancing:
    default:
      return 1;
  }
};
