import type { UseFormReturn } from 'react-hook-form';
import type { CtCartFragment } from '@ecomm/shop-cart/graphql/fragments.generated';
import type {
  ShopCartUpdateAction,
  ShopAddressDraft,
} from '@ecomm/shop-cart/graphql/types.generated';
import { ShopCartUpdateActionType } from '@ecomm/shop-cart/graphql/types.generated';
import { cartAndFormDataMatch } from '../utils';

export const getEmailUpdateIfNeeded = (
  shopCart: CtCartFragment,
  form: UseFormReturn,
): ShopCartUpdateAction[] => {
  const {
    getValues,
    formState: { errors },
  } = form;
  const emailValue = getValues('email');
  if (!errors.email && shopCart?.customerEmail !== emailValue && emailValue.length) {
    return [
      {
        actionType: ShopCartUpdateActionType.SetCustomerEmail,
        setCustomerEmail: {
          customerEmail: emailValue,
        },
      },
    ];
  }
  return [];
};

export const getPrivacyUpdateIfNeeded = (
  shopCart: CtCartFragment,
  form: UseFormReturn,
): ShopCartUpdateAction[] => {
  const shouldAllowMarketing = form.getValues('shouldAllowMarketing');

  if (shopCart?.customerPreferences?.shouldAllowMarketing !== shouldAllowMarketing) {
    return [
      {
        actionType: ShopCartUpdateActionType.SetCustomerPreferences,
        setCustomerPreferences: {
          customerPreferences: {
            shouldAllowMarketing,
          },
        },
      },
    ];
  }
  return [];
};

export const getShippingAddressUpdateIfNeeded = (
  shopCart: CtCartFragment,
  form: UseFormReturn,
): ShopCartUpdateAction[] => {
  const addressFormValues = form.getValues('shippingAddress');

  const address = {
    ...addressFormValues,
  } as ShopAddressDraft;

  const noErrors = Object.keys(form.formState.errors).length === 0;

  const hasChanges = !cartAndFormDataMatch({
    cart: shopCart?.shippingAddress ?? {},
    form: addressFormValues,
  });

  const shouldUpdateShipping = noErrors && hasChanges;
  if (shouldUpdateShipping) {
    return [
      {
        setShippingAddress: {
          address,
        },
        actionType: ShopCartUpdateActionType.SetShippingAddress,
      },
    ];
  }
  return [];
};

export const getBillingAddressUpdateIfNeeded = (
  shopCart: CtCartFragment,
  form: UseFormReturn,
): ShopCartUpdateAction[] => {
  const addressFormValues = form.getValues('billingAddress');
  const isBillingSameAsShipping = form.getValues('isBillingSameAsShipping');
  const requiredFields = form.getValues([
    'billingAddress.street1',
    'billingAddress.city',
    'billingAddress.state',
    'billingAddress.postalCode',
  ]);
  const requiredFieldsFilledOut = requiredFields.every(Boolean);
  const setAddressToNull = isBillingSameAsShipping || !requiredFieldsFilledOut;
  const address = setAddressToNull ? null : addressFormValues;
  const noErrors = Object.keys(form.formState.errors).length === 0;

  const hasChanges = !cartAndFormDataMatch({
    cart: shopCart?.billingAddress,
    form: address,
  });

  const shouldUpdateShipping = noErrors && hasChanges;
  if (shouldUpdateShipping) {
    return [
      {
        setBillingAddress: {
          address,
        },
        actionType: ShopCartUpdateActionType.SetBillingAddress,
      },
    ];
  }

  return [];
};
