import { useHasVariation } from '@peloton/split-testing/hooks';

const FINANCING_0_PERCENT_EXPERIMENT = '0 Financing';
const FINANCING_0_PERCENT_VARIATION = 'Variation #1';

const useIsFinancing0PercentVariationActive = () => {
  return useHasVariation(FINANCING_0_PERCENT_EXPERIMENT, FINANCING_0_PERCENT_VARIATION);
};

export default useIsFinancing0PercentVariationActive;
